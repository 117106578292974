import Plausible from 'plausible-tracker';
import clientCheck from './clientCheck';

var plausible: any = null;
const initPlausible = (mode: 'production' | 'staging') => {
	// console.log({ mode });
	plausible = Plausible({
		domain: mode === 'production' ? 'northrock.software' : 'staging.northrock.software',
		// trackLocalhost: false,
		apiHost: 'https://analytics.northrock.systems',
	});
};

const trackPageview = () => {
	if (clientCheck()) {
		if (ENV_MODE === 'staging' || ENV_MODE === 'production') {
			plausible.trackPageview();
		}
	}
	// plausible.trackPageview();
};

const trackEvent = (eventName: any, eventProps: any = undefined) => {
	if (clientCheck()) {
		if (ENV_MODE === 'staging' || ENV_MODE === 'production') {
			plausible.trackEvent(eventName, eventProps);
		}
	}
	// plausible.trackEvent(eventName, eventProps);
};

export { trackPageview, trackEvent, initPlausible };
